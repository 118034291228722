import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { domain } from './config';

const UserProfile = () => {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true); // Состояние загрузки
    const [translations, setTranslations] = useState({}); // Состояние для переводов
    const navigate = useNavigate();

    // Функция для получения переводов
    const fetchTranslations = async (language, initData) => {
        const keys = [
            "profile_label",
            "first_seen",
            "balance_label",
            "history_button"
        ];
        try {
            const response = await fetch(`${domain}/get-translations`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    'authorization': initData,
                },
                body: JSON.stringify({ keys, language }), // Указываем язык
            });
            const result = await response.json();
            setTranslations(result);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        const initTg = async () => {
            if (typeof window !== 'undefined' && window.Telegram && window.Telegram.WebApp) {
                const tgData = window.Telegram.WebApp;
                const initData = tgData.initData;

                try {
                    const response = await fetch(`${domain}/user`, {
                        method: "GET",
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'authorization': initData,
                            'ngrok-skip-browser-warning': "asd"
                        }
                    });

                    if (!response.ok) {
                        tgData.close();
                        throw new Error(`HTTP error! status: ${response.status}`);
                    }

                    const data = await response.json();
                    setUser(data.user);
                    await fetchTranslations(data.user.language_code, initData); // Получаем переводы
                    setLoading(false); // Завершение загрузки
                } catch (error) {
                    console.error("Error fetching data:", error);
                }
            } else {
                setTimeout(initTg, 500);
            }
        };

        initTg();

    }, []);

    if (loading) {
        return <div className="loading">Загрузка профиля...</div>; // Загрузка с анимацией
    }

    return (
        <div className="profile-container">
            <button className="create-order-btn" onClick={() => navigate('/history')}>
                {translations.history_button}
            </button>

            <h2>{translations.profile_label}</h2>
            <p>
                {translations.first_seen} {new Date(user.created_at).getDate()}/{new Date(user.created_at).getMonth() + 1}/{new Date(user.created_at).getFullYear()}
            </p>
            <p>
                {translations.balance_label} ${user.balance}
            </p>
        </div>
    );
};

export default UserProfile;
